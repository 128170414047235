import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  public appPages = [
    { title: 'Meps by state', url: '/folder/Inbox' },
    { title: 'Meps by group', url: '/folder/Outbox' },
    { title: 'Meps by commité', url: '/folder/Favorites' },
    { title: 'all meps', url: '/mep-detail' },
  ];

  darkMode: boolean = true;

  constructor() { 
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    this.darkMode = prefersDark.matches;
  }

  ngOnInit() {}

  toggleDarkMode() {
    this.darkMode = !this.darkMode;
    document.body.classList.toggle('dark');
  }

}
